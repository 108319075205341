import { postToPayServer,postToSaleServer,postToItemServer,postToOrderServer } from './common';
/**
 * 订单-付款方式列表（交易中心订单）
 */
export async function getPayType() {
    return await postToOrderServer("/paytype/list");
}
/**
 * 采购订单-支付获取回调url
 * @param {*} orderId 订单id
 */
export async function payGetUrl(){
    return await postToSaleServer(`/pay/cb_url`);
}
/**
 * 打样-预支付交易id
 * @param {*} trans_id 待支付交易id
 */
export async function getTransId(id){
    return await postToOrderServer(`/order/pay/${id}`);
}
/**
 * 线下支付
 */
// export async function offlinePay(obj){
//     return await postToPayServer('/offline/pay',obj);
// }
export async function offlinePay(obj){
    return await postToOrderServer('/order/offline/pay',obj);
}
/**
 * 获得支付详情
 * @param {*} orderId 订单id
 */
export async function payId(id){
    return await postToPayServer(`/get/${id}`);
}
/**
 * 线下支付-付款设计师版权销售
 */
export async function offlinePayBq(obj){
    return await postToItemServer('/factory/pay4-price-product',obj);
}
/**
 * 线下支付-确认设计师版权销售付款
 */
export async function QrofflinePayBq(obj){
    return await postToItemServer('/designer/check-paid-price-product',obj);
}
/**
 * 收款方确认收款
 * @param {*} orderId 订单id
 */
export async function payConfirmId(type,id){
    return await postToPayServer(`/offline/confirm/order/${type}/${id}`);
}
/**
 * 收款方确认收款(打样)
 * @param {*} orderId 订单id
 */
export async function qrOfflinePay(id){
    return await postToPayServer(`/offline/confirm/${id}`);
}
/**
 * 获得支付列表(业务订单号+业务类型)
 * @param {*} orderId 订单id
 */
export async function getPayList(order_id,obj){
    return await postToPayServer(`/list/${order_id}`,obj);
}
/**
 * 获得支付详情(业务订单号+业务类型)
 * @param {*} orderId 订单id
 */
export async function getPayId(type,order_id){
    return await postToPayServer(`/get_by_order/${type}/${order_id}`);
}

/**----------------------------微信支付api----------------------------------------
 * 获得微信支付二维码
 */
export async function wxQrcode(obj){
    return await postToPayServer('/wx/qrcode',obj);
}
/**
 * 查询支付结果
 */
export async function wxQueryId(order_no){
    return await postToPayServer(`/wx/query/${order_no}`);
}